import { css } from "styled-components";

export const Theme = {
  fonts: {
    default: css`
      font-family: "Pretendard";
    `,
    logo: css`
      font-family: "RiaSans-ExtraBold";
      font-size: 25px;
      color: #091057;
    `,
    title: css``,
    others: css``,
  },
  colors: {
    white: "#fff",
    black: "#000",
    black2: "#333332",
    gray: "#9A9A9A",
    gray2: "#c4c4c4",
    gray3: "#E9E9E9",
    logoNavy: "#091057",
    navy: "#102C57",
    blue: "#133E87",
    deepBlue: "#4A628A",
    lightBlue: "#CBDCEB",
  },
  breakpoints: {},
};
